const fsahsa = `
  <table class="mceNonEditable" width="100%" border="0" cellspacing="0" cellpadding="10" style="font-family: Arial, Helvetica, sans-serif; background-color: #ffffff; margin: 0px;" align="center" data-node="column">
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="max-width: 500px; margin: auto;" data-node="column">
          <tr>
            <td align="center" valign="middle" width="48%">
              <img class="mceEditable" src="https://storage.googleapis.com/eblast-production/t_primary_vision_2.png" style="display: inline-block; height: auto; width: 100%; max-width: 100px; padding: 0px; border: 0px solid #000000; vertical-align: middle;" />
            </td>
            <td align="center" valign="middle" width="2%">
              <span style="border-right: 1px solid #2c3539; margin: 0px 2.7%; vertical-align: middle; height: 38px; display: inline-block;"> </span>
            </td>
            <td align="center" valign="middle" width="48%">
              <span style="display: inline-block; font-family: Arial, Helvetica, sans-serif; font-size: 12px; color: #000000; background-color: #ffffff; font-weight: normal; font-style: normal; text-decoration: none; line-height: 1.3; vertical-align: middle; margin-right: 5px;" data-node="text">A proud member of</span>

              <img src="https://storage.googleapis.com/eblast-production/t_essilor-experts-2.png" style="display: inline-block; height: auto; width: 100%; max-width: 58px; padding: 0px; margin: 0px; border: 0px solid #000000; vertical-align: middle;" />
            </td>
          <tr/>
        </table>
      </td>
    </tr>
  </table>

  <table width="100%" border="0" cellspacing="0" cellpadding="15" style="font-family: Arial, Helvetica, sans-serif; background-color: #107D84; margin: 0px;" align="center" data-node="column">
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="10" style="font-family: Arial, Helvetica, sans-serif; background-color: #107D84; margin: 0px;" align="center" data-node="column">
          <tr>
            <td align="center" valign="middle">
              <img src="https://storage.googleapis.com/eblast-production/t_calendar.png" style="display: block; height: auto; width: 8%; margin-left: auto; margin-right: auto; padding: 0px; border: 0px solid #000000;" />
            </td>
          </tr>
        </table>
        <table width="100%" border="0" cellspacing="0" cellpadding="10" style="font-family: Arial, Helvetica, sans-serif; background-color: #107D84; margin: 0px;" align="center" data-node="column">
          <tr>
            <td align="center" valign="middle">
              <span style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 22px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.3;" data-node="text">{{ PATIENT.FIRST_NAME }}, time is running out on using your FSA this year!</span>
            </td>
          </tr>
        </table>
        <table width="100%" border="0" cellspacing="0" cellpadding="10" style="font-family: Arial, Helvetica, sans-serif; background-color: #107D84; margin: 0px;" align="center" data-node="column">
          <tr>
            <td align="center" valign="middle">
              <span style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 16px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2;" data-node="text">If you don’t use it, you’ll lose it!<br/>Take advantage and book an eye appointment today!</span>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 16px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">
    <img style="display: block; height: auto; width: 100%; margin-left: auto; margin-right: auto; padding: 0px; border: 0px solid #000000;" src="https://storage.googleapis.com/eblast-production/b_c_images-17.jpg" alt="EBlast-img" />
  </p>

  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>

  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">
    <a style="padding: 0px 15px; font-weight: bold; display: block; width: max-content; background-color: #107D84; color: #ffffff; border-radius: 8px; line-height: 45px; margin-left: auto; margin-right: auto; border: 0px solid #000000; text-decoration: none;" href="https://4patientcare.com/booknow" target="_blank" rel="noopener" data-node="button" data-shape="round">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; BOOK NOW&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</a>
  </p>

  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>

  <table width="100%" border="0" cellspacing="0" cellpadding="15" style="font-family: Arial, Helvetica, sans-serif; background-color: #ffffff; margin: 0px;" align="center" data-node="column">
    <tr>
      <td align="center" valign="middle">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="font-family: Arial, Helvetica, sans-serif; background-color: #ffffff; margin: 0px; max-width: 520px;" align="center" data-node="column">
          <tr>
            <td align="center" valign="middle">
              <span style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 16px; color: #107D84; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: left; line-height: 1.3;" data-node="text">Your FSA Funds are set to expire. Don’t wait!</span>
            </td>
          </tr>
          <tr>
            <td align="center" valign="middle">
              <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>

              <span style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 16px; color: #000000; background-color: #ffffff; font-weight: normal; font-style: normal; text-decoration: none; text-align: left; line-height: 1.3;" data-node="text">Scheduling a new appointment is easier than you think. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc consequat cursus orci vel fringilla. Cras porta velit turpis, id hendrerit tortor malesuada sed. Proin malesuada convallis volutpat. Fusce non nisi nec eros fermentum scelerisque. Quisque ornare lorem justo.</span>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>

  <table width="100%" border="0" cellspacing="0" cellpadding="15" style="font-family: Arial, Helvetica, sans-serif; background-color: #107D84; margin: 0px;" align="center" data-node="column">
    <tr>
      <td align="center" valign="middle">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="font-family: Arial, Helvetica, sans-serif; background-color: #107D84; margin: 0px; max-width: 520px;" align="center" data-node="column">
          <tr>
            <td align="center" valign="middle">
              <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
              <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
              <span style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 23px; color: #ffffff; background-color: #107D84; font-weight: normal; font-style: normal; text-decoration: none; text-align: left; line-height: 1.2;" data-node="text">IF YOU HAVE ANY QUESTIONS,</span>
            </td>
          </tr>
        </table>
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="font-family: Arial, Helvetica, sans-serif; background-color: #107D84; margin: 0px; max-width: 520px;" align="center" data-node="column">
          <tr>
            <td valign="middle">
              <span style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 23px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: left; line-height: 1.2;" data-node="text">PLEASE REACH OUT.</span>
            </td>
          </tr>
        </table>
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="font-family: Arial, Helvetica, sans-serif; background-color: #107D84; margin: 0px; max-width: 520px;" align="center" data-node="column">
          <tr>
            <td valign="middle">
              <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
              <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
              <span style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 16px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: left; line-height: 1.2;" data-node="text">{{ PRACTICE.NAME }}</span>
            </td>
          </tr>
        </table>
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="font-family: Arial, Helvetica, sans-serif; background-color: #107D84; margin: 0px; max-width: 520px;" align="center" data-node="column">
          <tr>
            <td valign="middle">
              <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 6px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
              <span style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 16px; color: #ffffff; background-color: #107D84; font-weight: normal; font-style: normal; text-decoration: none; text-align: left; line-height: 1.2;" data-node="text">{{ PRACTICE.ADDR1 }} {{ PRACTICE.ADDR2 }} {{ PRACTICE.CITY }} {{ PRACTICE.STATE }} {{ PRACTICE.ZIP }}</span>
            </td>
          </tr>
        </table>
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="font-family: Arial, Helvetica, sans-serif; background-color: #107D84; margin: 0px; max-width: 520px;" align="center" data-node="column">
          <tr>
            <td valign="middle">
              <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 6px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
              <span style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 16px; color: #ffffff; background-color: #107D84; font-weight: normal; font-style: normal; text-decoration: none; text-align: left; line-height: 1.2;" data-node="text">{{ PRACTICE.PHONE }}</span>
              <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
              <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #ffffff; background-color: #107D84; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>

  <table class="mceNonEditable" width="100%" border="0" cellspacing="0" cellpadding="15" style="font-family: Arial, Helvetica, sans-serif; background-color: #ffffff; max-width: 520px;" align="center" data-node="column">
    <tr>
      <td align="center" valign="middle">
        <span style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 16px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.5;" data-node="text">Our practice is a proud partner of Essilor because their lens technology helps us to improve our patient’s quality of life.</span>
      </td>
    </tr>
  </table>

  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>

  <table class="mceNonEditable" width="100%" border="0" cellspacing="0" cellpadding="10" style="font-family: Arial, Helvetica, sans-serif; background-color: #ffffff; margin: 0px;" align="center" data-node="column">
    <tr>
      <td>
        <img style="display: block; height: auto; width: 34%; margin-left: auto; margin-right: auto; padding: 0px; border: 0px solid #000000;" src="https://storage.googleapis.com/eblast-production/t_essilor-seemore.jpg" alt="EBlast-img" />
      </td>
    </tr>
  </table>

  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>
  <p style="display: block; font-family: Arial, Helvetica, sans-serif; font-size: 14px; color: #000000; background-color: #ffffff; font-weight: bold; font-style: normal; text-decoration: none; text-align: center; line-height: 1.2; margin: 0;" data-node="text">&nbsp;</p>

  <table class="mceNonEditable" width="100%" border="0" cellspacing="0" cellpadding="10" style="font-family: Arial, Helvetica, sans-serif; background-color: #edeced; margin: 0px;" align="center" data-node="column">
    <tr>
      <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="max-width: 520px; margin: auto;" data-node="column">
          <tr>
            <td align="center" valign="middle" width="30%">
              <img class="mceEditable" src="https://storage.googleapis.com/eblast-production/t_primary_vision_2.png" style="display: inline-block; height: auto; width: 60%; padding: 0px; border: 0px solid #000000; vertical-align: middle;" />
            </td>
            <td align="center" valign="middle" width="2%">
              <span style="border-right: 1px solid #2c3539; margin: 0px 2.7%; vertical-align: middle; height: 38px; display: inline-block;">&nbsp;</span>
            </td>
            <td align="right">
              <a style="padding: 0px 4px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; font-weight: normal; color: #000000; text-decoration: underline; background-color: #edeced;" href="https://eblast-dot-tough-zoo-144917.uc.r.appspot.com/unsubscribe?PatientID={{ PATIENT.ID }}&CoverKey={{ PRACTICE.COVERKEY }}&CampaignID={{ CAMPAIGN.ID }}" target="_blank" rel="noopener">Unsubscribe</a>
            </td>
            <td align="center" width="2%">
              <span style="border-right: 1px solid #2c3539; margin: 10px;">&nbsp;</span>
            </td>
            <td align="left">
              <a style="padding: 0px 4px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; font-weight: normal; color: #000000; text-decoration: underline; background-color: #edeced;" href="https://4patient.care/assets/4pc-privacy-statement.pdf" target="_blank" rel="noopener">Privacy Policy</a>
            </td>
          <tr/>
        </table>
      </td>
    </tr>
  </table>
`

export default fsahsa