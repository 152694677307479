import React from "react"
import { DesktopOutlined, MobileOutlined } from "@ant-design/icons"

import CustomModal from "@src/components/ui/modal"
import tw, { css } from "twin.macro"

export const TemplateIframe = ({
  templateContent,
  scrollable = false,
  clickable = true,
}: {
  templateContent: any
  scrollable?: boolean
  clickable?: boolean
}): React.ReactElement => (
  <iframe
    width="100%"
    height="100%"
    scrolling={scrollable ? "yes" : "no"}
    srcDoc={`
            <html>
              <head>
                <link rel="stylesheet" href="/public.css">
              </head>
              <body style="pointer-events: ${clickable ? "auto" : "none"}">
                ${templateContent}
              </body>
            </html>
          `}
  ></iframe>
)

interface TemplatePreviewProps {
  templateContent: string
  isDesktopPreview?: boolean
  setIsDesktopPreview?: React.Dispatch<React.SetStateAction<boolean>>
  showPreview?: boolean
  setShowPreview?: React.Dispatch<React.SetStateAction<boolean>>
}

const TemplatePreview = ({
  templateContent,
  scale,
  scrollable = false,
  clickable = true,
}: TemplatePreviewProps & {
  scale: number
  scrollable?: boolean
  clickable?: boolean
}): React.ReactElement => {
  return (
    <div
      css={[
        tw`relative w-full h-full overflow-hidden`,
        css`
          padding-top: 2px;
        `,
      ]}
    >
      <div
        css={css`
          transform-origin: top left;
          overflow: ${scrollable ? "auto" : "hidden"};
          transform: scale(${scale});
          width: ${100 / scale}%;
          height: ${100 / scale}%;
        `}
      >
        <TemplateIframe
          templateContent={templateContent}
          scrollable={scrollable}
          clickable={clickable}
        />
      </div>
    </div>
  )
}

export const TemplatePreviewModal = ({
  templateContent,
  isDesktopPreview = true,
  setIsDesktopPreview,
  showPreview = true,
  setShowPreview,
}: TemplatePreviewProps): React.ReactElement => {
  return (
    <CustomModal
      title={
        <div
          tw="flex bg-white ml-4 items-center px-4"
          css={css`
            height: 35px;
            width: fit-content;
            border-radius: 4px 4px 0px 0px;
          `}
        >
          <span tw="text-black mr-4 text-sm">PREVIEW MODE:</span>
          <div tw="flex">
            <DesktopOutlined
              style={{
                color: isDesktopPreview ? "#000" : "#718096",
                marginRight: 10,
              }}
              onClick={() => setIsDesktopPreview && setIsDesktopPreview(true)}
            />
            <MobileOutlined
              style={{ color: !isDesktopPreview ? "#000" : "#718096" }}
              onClick={() => setIsDesktopPreview && setIsDesktopPreview(false)}
            />
          </div>
        </div>
      }
      isVisible={showPreview}
      onCancel={() => setShowPreview && setShowPreview(false)}
      footer=""
      width={isDesktopPreview ? 600 : 414}
      customCss={css`
        & .ant-modal-content {
          border-radius: 0px !important;
          background-color: transparent !important;
        }
        & .ant-modal-header {
          background-color: transparent !important;
          padding: 0px !important;
        }
        & .ant-modal-body {
          background-color: #fff !important;
        }
        & .ant-modal-close-x {
          height: 35px !important;
          line-height: 35px !important;
          width: 35px !important;
          background-color: #fff;
          border-radius: 4px 4px 0px 0px !important;
        }
      `}
    >
      <div
        tw="w-full"
        css={css`
          height: 700px;
        `}
      >
        <TemplateIframe scrollable templateContent={templateContent} />
      </div>
    </CustomModal>
  )
}

export default TemplatePreview
