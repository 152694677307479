import React from "react"
import { css } from "twin.macro"
import { Select } from "antd"

import { TemplateIframe } from "@src/components/template/preview"
import BirthdayTemplate from "@src/sections/creator/birthday"
import FSAHSATemplate from "@src/sections/creator/fsa_hsa"
import AnnualShow from "@src/sections/creator/annualShow"
import Retiring from "@src/sections/creator/retiring"
import Holiday from "@src/sections/creator/holiday"
import PostCovid from "@src/sections/creator/postCovid"
import Promo from "@src/sections/creator/promo"

interface Template {
  name: string
  template: string
}

const templates: Template[] = [
  { name: "FSA/HSA", template: FSAHSATemplate },
  { name: "Birthday", template: BirthdayTemplate },
  { name: "Annual Show", template: AnnualShow },
  { name: "Promo", template: Promo },
  { name: "Retiring", template: Retiring },
  { name: "Holiday", template: Holiday },
  { name: "Post Covid", template: PostCovid },
]

const Creator = (): React.ReactElement => {
  const [template, setTemplate] = React.useState<string>(templates[0].name)
  const selectedTemplate = templates.find(t => t.name === template)

  return (
    <>
      <div tw="flex flex-row justify-center p-4">
        <Select
          defaultValue={template}
          onChange={v => setTemplate(v)}
          tw="w-40"
        >
          {templates.map(t => (
            <Select.Option key={t.name} value={t.name}>
              {t.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div tw="flex flex-row justify-around px-4">
        {/* desktop */}
        <div
          tw="border border-gray-400"
          css={css`
            width: 600px;
            box-sizing: content-box;
            height: 90vh;
          `}
        >
          <TemplateIframe
            scrollable
            templateContent={selectedTemplate?.template}
          />
        </div>

        {/* mobile */}
        <div
          tw="border border-gray-400"
          css={css`
            width: 414px;
            box-sizing: content-box;
            height: 90vh;
          `}
        >
          <TemplateIframe
            scrollable
            templateContent={selectedTemplate?.template}
          />
        </div>
      </div>
    </>
  )
}

export default Creator
